<template>
  <div class="container">
    <BreadCrumb
        :links="[
          {
            name: 'Fabric Translate'
          }
        ]"
    />
    <ApplicationExtractedTextList />
  </div>
</template>
<script>
import ApplicationExtractedTextList from "@/components/ml-operations/ApplicationExtractedTextList";

export default {
  name: "MLOperations",
  components: {
    ApplicationExtractedTextList
  },
  data: () => ({
    view: "ApplicationExtractedTextList"
  }),
  methods: {}
};
</script>
