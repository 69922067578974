<template>
  <div class="card">
    <div class="card-body">
      <SearchApplications
        type="ml"
        @reset="handleReset"
        @result="handleResult"
      />
      <ApplicationList
        :applications-prop="applicationsComp"
        :links-prop="links"
        :meta-prop="meta"
        :search-application="searchApplicationResult"
        @selected="handleSelect"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import ApplicationList from "@/components/lists/ApplicationList";
import SearchApplications from "@/components/search/SearchApplications";
export default {
  name: "ApplicationExtractedTextList",
  components: {
    ApplicationList,
    SearchApplications
  },
  emits: ["selected"],
  data: () => ({
    applications: [],
    links: [],
    meta: [],
    searchApplicationResult: null
  }),
  computed: {
    applicationsComp() {
      return this.applications;
    }
  },
  methods: {
    ...mapMutations([
      "setExtractedText",
      "setApplicationUuid",
      "setTracedPensionUuid"
    ]),
    ...mapActions(["getApplicationsWithMLDocs", "getExtractedText"]),
    handleReset() {
      this.searchApplicationResult = null;
    },
    handleResult(result) {
      this.searchApplicationResult = result;
    },
    async handleSelect(uuid) {
      this.$router.push({
        name: "Fabric Translate Application",
        params: {
          auuid: uuid
        }
      });
    }
  },
  async beforeMount() {
    // let response = await this.getApplicationsWithMLDocs().catch(err =>
    //   console.log(err)
    // );
    // this.applications = response.data.data;
    // this.links = response.data.links;
    // this.meta = response.data.meta || null;
  }
};
</script>
